<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
// import APIService from '@/API/API'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetLabInfo',
  props: {
    trackName: String
  },
  data: function () {
    return {
      stepName: 'lab_info',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      otherAffiliations: [],
      search: '',
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.labInfoForm.reset()
    },
    submit() {
      const me = this
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              me.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    }
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
    filteredOrgList() {
      if (this.organizations.length) {
        // Pull out existing affiliations other than primary
        return this.organizations.filter((org) => org.slug !== this.onBoardRequest.primaryAffiliation)
      }
      return []
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        if (!me.onBoardRequest.labInfo) {
          me.onBoardRequest.labInfo = {}
        }
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Lab / Company</h1><p>&nbsp;</p>
      <v-form id="lab-info-form" ref="labInfoForm" v-model="isValid" @submit.prevent="submit">

        <v-flex>
          <h3>Lab or Company Name</h3>
          <v-layout row class="ma-1">
            <v-text-field
              v-model="onBoardRequest.labInfo.lab_name"
              label="Name"
              color="secondary"
              ref="labName"
              rows="4"
            >
            </v-text-field>
          </v-layout>
          <h3>PI or Manager</h3>
          <div>The person primarily responsible for the project</div>
          <v-layout class="ma-1" row>
            <v-flex class="ma-1">
              <v-text-field
                v-model="onBoardRequest.labInfo.pi_name"
                label="Name"
                color="secondary"
                ref="piName"
                required
              >
              </v-text-field>
            </v-flex>
            <v-flex class="ma-1">
              <v-text-field
                v-model="onBoardRequest.labInfo.pi_email"
                label="Email"
                color="secondary"
                ref="piEmail"
                :rules="emailRules"
                required
                >
              </v-text-field>
            </v-flex>
          </v-layout>
          <h3>Billing Contact</h3>
          <div>The person responsible for billing and payment.  May be the same as the PI / Manager.</div>
          <v-layout class="ma-1" row>
            <v-flex class="ma-1">
              <v-text-field
                v-model="onBoardRequest.labInfo.billing_contact_name"
                label="Name"
                color="secondary"
                ref="billingContactName"
              ></v-text-field>
            </v-flex>
            <v-flex class="ma-1">
              <v-text-field
                v-model="onBoardRequest.labInfo.billing_contact_email"
                label="Email"
                color="secondary"
                ref="billingContactEmail"
                :rules="emailRules"
                ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#lab-info-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
h3 {
  margin-top: 2em;
}
</style>
