<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetManagedLabs',
  props: {
    trackName: String,
    orgTree: {
      type: String,
      default: 'Harvard',
    },
    ranks: {
      type: String,
      default: 'lab,facility',
    },
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    },
  },
  data: function () {
    return {
      stepName: 'managed_labs_collected',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.managedLabsForm.reset()
    },
    submit() {
      const me = this

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              this.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Select one or more labs.  Start typing to search.'
      return this.loading ? `${base} (loading)` : base
    },
  },
  mounted: function () {
    const me = this

    this.loading = true
    this.$api
      .getOrgNames(this.orgTree, null, this.ranks)
      .then((res) => {
        me.organizations = res.data
        getCurrentOnBoardRequest()
          .then((res2) => {
            me.onBoardRequest = res2
            me.$nextTick(() => me.$refs.orgSelect.focus())
          })
          .catch((err) => me.showMessage(err))
      })
      .catch((err) => me.showMessage(err))
      .finally((me.loading = false))
  },
}
</script>
<template>
  <v-layout justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>What lab(s) do you manage?</h1>
      <p>Select one or more labs for which you serve as a lab administrator</p>
      <p>
        If you cannot find your lab, please
        <a :href="`mailto:${helpEmail}`">send in a ticket.</a>
      </p>
      <v-form id="managed-labs-form" ref="managedLabsForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-autocomplete
            :items="organizations"
            key="organization-list"
            ref="orgSelect"
            autocomplete="new-password"
            :placeholder="searchPlaceholder"
            item-text="name"
            item-value="slug"
            prepend-icon="search"
            :loading="loading"
            hide-no-data
            :rules="[(v) => !!v || 'At least one lab must be selected.']"
            v-model="onBoardRequest.managedLabs"
            :return-object="false"
            multiple
            solo
            clearable
          ></v-autocomplete>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Submit</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#managed-labs-form {
  margin-top: 1rem;
}
</style>
