<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetAllAffiliations',
  props: {
    trackName: String,
    orgTree: {
      type: String,
      default: 'Harvard',
    },
    ranks: {
      type: String,
      default: 'lab,department,facility,center,museum,division,institute,school,institution',
    },
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    },
    getOtherAffiliations: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: function () {
    return {
      stepName: 'affiliation_collected',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      otherAffiliations: [],
      search: '',
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.primaryAffiliationForm.reset()
    },
    skip() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
    submit() {
      const me = this

      // Update affiliations if we're getting them
      if (this.getOtherAffiliations) {
        // Only additions are done, users can't remove affiliations from here
        this.otherAffiliations.forEach((other) => {
          // An "undefined" org shows up in otherAffiliations
          if (other.slug && this.onBoardRequest.person.affiliations?.find((org) => org.slug === other.slug) === undefined) {
            this.onBoardRequest.person.affiliations?.push(
              { role: 'member', active: true, slug: other.slug }
            )
          }
        })
      }

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              me.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
    filteredOrgList() {
      if (this.organizations.length) {
        // Pull out existing affiliations other than primary
        return this.organizations.filter((org) => org.slug !== this.onBoardRequest.primaryAffiliation)
      }
      return []
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    this.$api
      .getOrgNames(this.orgTree, null, this.ranks)
      .then((res) => {
        me.organizations = res.data
        getCurrentOnBoardRequest()
          .then((res2) => {
            me.onBoardRequest = res2
            // Pull out existing affiliations other than primary
            const nonPrimary = me.onBoardRequest.person.affiliations?.filter(
              (org) => org.slug !== me.onBoardRequest.primaryAffiliation && org.active
            )
            if (nonPrimary) {
              me.otherAffiliations = nonPrimary.map((org) => {
                const parsedOrg = this.$api.organization.parseSlug(org.slug)
                return { name: parsedOrg.name, slug: org.slug }
              })
              me.$nextTick(() => me.$refs.orgSelect.focus())
            }
          })
          .catch((err) => me.showMessage(err))
      })
      .catch((err) => me.showMessage(err))
      .finally((me.loading = false))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>What is your primary affiliation?</h1><p>&nbsp;</p>
      <p><strong>PIs, lab managers, and financial staff</strong> select your department, school or company.</p>
      <p><strong>All others</strong> select your lab</p>
      <p>
        If you cannot find an appropriate affiliation, please
        <a :href="`mailto:${helpEmail}`">send in a ticket.</a>
      </p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-combobox
            :items="organizations"
            key="organization-list"
            ref="orgSelect"
            autocomplete="new-password"
            :placeholder="searchPlaceholder"
            item-text="name"
            item-value="slug"
            prepend-icon="search"
            :loading="loading"
            hide-no-data
            :rules="[(v) => !!v || 'Affiliation is required.']"
            v-model="onBoardRequest.primaryAffiliation"
            :return-object="false"
          ></v-combobox>
        </v-flex>
        <div class="mt-5" v-if="getOtherAffiliations">
          <h2>What are your other affiliations?</h2><p>&nbsp;</p>
          <p>Select any additional labs, especially if you use resources or funding from that lab.</p>
          <p>
            If you cannot find an appropriate affiliation, please
            <a :href="`mailto:${helpEmail}`">send in a ticket.</a>
          </p>
          <v-flex>
            <v-autocomplete
              multiple
              :items="filteredOrgList"
              :placeholder="searchPlaceholder"
              item-text="name"
              item-value="slug"
              prepend-icon="search"
              :loading="loading"
              hide-no-data
              v-model="otherAffiliations"
              :return-object="true"
              chips
              deletable-chips
              clear-icon="mdi-close-circle"
              clearable
              hide-selected
              :search-input.sync="search"
              @change="search = ''"
            ></v-autocomplete>
          </v-flex>
        </div>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Submit</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
