<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetDemographicInfo',
  props: {
    trackName: String,
    orgTree: {
      type: String,
      default: 'Harvard',
    },
    ranks: {
      type: String,
      default: 'lab,department,facility,center,museum,division,institute,school,institution',
    },
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    },
    // getOtherAffiliations: {
    //   type: Boolean,
    //   default: true,
    //   required: false,
    // },
  },
  data: function () {
    return {
      stepName: 'demographic_info',
      loading: false,
      generic: [(v) => !!v || 'This field is required.'],
      isValid: false,
      onBoardRequest: {},
      otherAffiliations: [],
      search: '',
      raceOptions: ['White', 'Black or African American', 'Asian', 'Native American or Alaska Native', 'Native Hawaiian or Pacific Islander'], // , 'Other'
      ethnicityOptions: ['Non Hispanic', 'Hispanic'],
      genderOptions: ['Male', 'Female', 'Enter your own'],
      citizenshipOptions: ['U.S. Citizen', 'U.S. Permanent Resident', 'Visa', 'Other'],
      selectedGender: '', // watch selected gender, on change update
      citizenship: '', // Doing this to make visa details and national origin fields react
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.demographicInfoForm.reset()
    },
    submit() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
  },
  watch: {
    citizenship: {
      // Doing this to make visa details and national origin fields react
      handler: function (newVal) {
        this.$set(this.onBoardRequest, 'citizenship', newVal)
      },
      deep: true
    },
    selectedGender: {
      handler: function (newVal) {
        if (newVal !== 'Enter your own') {
          this.$set(this.onBoardRequest, 'gender', newVal)
        }
      },
      deep: true
    },
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res2) => {
        me.onBoardRequest = res2
        me.citizenship = me.onBoardRequest.citizenship
        me.gender = me.onBoardRequest.gender
      })
      .catch((err) => me.showMessage(err))
      .finally((me.loading = false))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Demographic Information</h1><p>&nbsp;</p>
      <p>Please fill out the form below.</p>
      <v-form id="demographic-info-form" ref="demographicInfoForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-select
            v-model="onBoardRequest.race"
            :items="raceOptions"
            label="Race"
            ref="race"
            color="secondary"
            clearable
            required
            :rules="generic"
            class="required"
          ></v-select>
          <v-select
            v-model="onBoardRequest.ethnicity"
            :items="ethnicityOptions"
            label="Ethnicity"
            ref="ethnicity"
            color="secondary"
            clearable
            required
            :rules="generic"
            class="required"
          ></v-select>
          <v-select
            v-model="selectedGender"
            :items="genderOptions"
            label="Select a gender"
            ref="selectedGender"
            color="secondary"
            clearable
            required
            :rules="generic"
            class="required"
          ></v-select>
          <v-text-field v-if="selectedGender === 'Enter your own'"
            v-model="onBoardRequest.gender"
            label="Enter a gender"
            color="secondary"
            ref="gender"
            required
            :rules="generic"
            class="required"
          ></v-text-field>
          <v-textarea
            v-model="onBoardRequest.disability"
            name="disability"
            label="Disability"
            color="secondary"
            ref="disability"
            auto-grow
            rows="2"
            variant="outlined"
          ></v-textarea>
          <v-select
            v-model="citizenship"
            :items="citizenshipOptions"
            label="Citizenship"
            ref="citizenship"
            color="secondary"
            clearable
            required
            :rules="generic"
            class="required"
          ></v-select>
          <v-text-field v-if="onBoardRequest.citizenship === 'Visa' || onBoardRequest.citizenship === 'Other'"
            v-model="onBoardRequest.visaDetails"
            label="Visa Details"
            color="secondary"
            ref="visaDetails"
            required
            :rules="generic"
            class="required"
          ></v-text-field>
          <v-text-field v-if="onBoardRequest.citizenship === 'Visa' || onBoardRequest.citizenship === 'Other'"
            v-model="onBoardRequest.origin"
            label="National Origin"
            color="secondary"
            ref="nationalOrigin"
            required
            :rules="generic"
            class="required"
          ></v-text-field>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#demographic-info-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
