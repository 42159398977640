/* eslint-disable no-useless-constructor */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import { API_ROOT } from '@/API/urls'
import forEach from 'lodash/forEach'
import router from '@/router'
import { IFXAPIService } from 'ifxvue'

const TRACK_DEFINITIONS = {}

class APIService extends IFXAPIService {
  async mountNextComponent(onBoardRequest) {
    const componentToRender = this.findNextComponent(onBoardRequest)
    if (componentToRender) {
      router.push({ name: componentToRender })
    } else {
      // Must be finished
      // router.push({ name: 'CompletedRequest' })
      console.error('Component not found')
      router.push({ name: 'Welcome' })
    }
  }

  findNextComponent(onBoardRequest) {
    // Iterates through tracks on the onboard request to
    // find the next null component
    const tracks = onBoardRequest.tracks.order
    let component = null
    forEach(tracks, (trackName) => {
      forEach(onBoardRequest.tracks[trackName].order, (stepName) => {
        const status = onBoardRequest.tracks[trackName][stepName].value
        if (status === 'incomplete') {
          component = onBoardRequest.tracks[trackName][stepName].component
          return false
        }
        return true
      })
      if (component !== null) {
        return false
      }
      return true
    })
    return component
  }

  getContinuationKey() {
    // Get it from session storage
    return sessionStorage.getItem('onboard_continuation_key')
  }

  setContinuationKey(continuation_key, continuation_key_expiration) {
    sessionStorage.setItem('onboard_continuation_key', continuation_key)
    if (continuation_key_expiration) {
      sessionStorage.setItem('onboard_continuation_key_expiration', continuation_key_expiration)
    }
  }

  getTrackInfo(onBoardRequest, type) {
    const tracks = onBoardRequest.data.tracks.order
    let info
    forEach(tracks, (trackName) => {
      forEach(onBoardRequest.data.tracks[trackName].order, (stepName) => {
        const status = onBoardRequest.data.tracks[trackName][stepName].value
        if (status === 'incomplete') {
          if (type === 'stepName') {
            info = stepName
          } else if (type === 'trackName') {
            info = trackName
          }
          return false
        }
        return true
      })
    })
    return info.toLowerCase()
  }

  checkEmail(onBoardRequest) {
    const url = `${API_ROOT}/get-person/`
    const params = {
      email: onBoardRequest.primaryEmail,
    }
    if (onBoardRequest.continuation_key) {
      params.continuation_key = onBoardRequest.continuation_key
    }
    return axios.get(url, { params: params })
  }

  checkExpenseCode(onBoardRequest) {
    const url = `${API_ROOT}/validate-code/`
    const params = {
      code: onBoardRequest.expenseCode,
    }
    return axios.get(url, { params: params })
  }

  checkName(fullName, continuationKey) {
    const params = {
      full_name: fullName,
    }
    if (continuationKey) {
      params.continuation_key = continuationKey
    }
    const url = `${API_ROOT}/get-person/`
    return axios.get(url, { params: params })
  }

  harvardKey(ticket, key) {
    const url = `${API_ROOT}/harvard-key/`
    return axios.post(url, { ticket: ticket, key: key })
  }

  getOnBoardRequest(key) {
    // Return OnBoardRequest object by key
    const url = `${API_ROOT}/onboardrequests/`
    return axios.get(url, { params: { key: key } })
  }

  updateOnBoardRequest(onBoardRequest) {
    let url = `${API_ROOT}/onboardrequests/`
    if (onBoardRequest.continuation_key) {
      url = `${url + onBoardRequest.continuation_key}/`
      return axios.put(url, onBoardRequest)
    }
    return axios.post(url, onBoardRequest)
  }

  sendVerificationEmail(onBoardRequest) {
    const key = onBoardRequest.continuation_key ? onBoardRequest.continuation_key : ''
    const url = `${API_ROOT}/send-verification-email/`
    return axios.post(url, { key: key, request_data: { data: onBoardRequest.data } })
  }

  getOrganizations() {
    const url = `${API_ROOT}/get-organizations/`
    return axios.get(url)
  }

  getOrgNames(org_tree, search, ranks) {
    const params = {}
    if (org_tree) {
      params.org_tree = org_tree
    }
    if (search) {
      params.search = search
    }
    if (ranks) {
      params.ranks = ranks
    }
    const url = `${API_ROOT}/get-org-names/`
    return axios.get(url, { params })
  }

  addAccessRequest(onBoardRequest, accessRequest) {
    onBoardRequest.data.tracks[accessRequest] = TRACK_DEFINITIONS[accessRequest]
    if (!onBoardRequest.data.tracks.order.includes(accessRequest)) {
      onBoardRequest.data.tracks.order.push(accessRequest)
    }
    if (!('access_requests' in onBoardRequest.data)) {
      onBoardRequest.data.access_requests = []
    }
    if (!onBoardRequest.data.access_requests.includes(accessRequest)) {
      onBoardRequest.data.access_requests.push(accessRequest)
    }
    return onBoardRequest
  }

  getLocationInfo(params) {
    const url = `${API_ROOT}/get-location-info/`
    return axios.get(url, { params })
  }

  isIntranetUsernameAvailable(username) {
    // Return true if username can be used for CNS intranet, false otherwise
    const url = `${API_ROOT}/check-nnin-admin-username/`
    return axios.get(url, { params: { username: username } })
  }

  isRCUsernameValid(username) {
    const url = `${API_ROOT}/check-rc-username/`
    return axios.get(url, { params: { username: username } })
  }

  loadRequestFile(fileObj, id) {
    const url = `${API_ROOT}/onboard-request-files/`
    const formData = new FormData()
    const { data, name, trackName, stepName } = fileObj
    formData.append('file_data', data)
    formData.append('name', name)
    formData.append('onboard_request', id)
    formData.append('track_name', trackName)
    formData.append('step_name', stepName)
    return axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  retrieveRequestFiles() {
    const url = `${API_ROOT}/onboard-request-files/`
    return axios.get(url)
  }

  getApproverContacts(organizations, role_filters) {
    // organizations is an array of objects with slug property
    const url = `${API_ROOT}/get-approver-contacts/`
    const organization_slugs = organizations.map((organization) => organization.slug)
    const query_params = { org_slugs: organization_slugs.join(',') }
    if (role_filters) {
      query_params.role_filters = role_filters
    }
    return axios.get(url, { params: query_params })
  }
}

// template
// <v-text-field
//   label="MOU file"
//   @click='pickFile'
//   v-model='file.name'
//   prepend-icon='attach_file'
//   hint="Upload MOU PDF"
// >
// </v-text-field>
// <input
//   type="file"
//   style="display: none"
//   ref="file"
//   accept="application/pdf"
//   @change="(e)=>onFilePicked(e)"
// >
// </input>

// data() {
//   return {
//     file: {
//       data: '',
//       name: '',
//       stepName: '',
//       trackName: '',
//     },
//   }
// },

// methods: {
// submit() {
//   if (this.file.data) {
//     this.item.onboardRequestFile = this.file
//   }
//   this.onBoardRequest.addTracksFromAccessRequests()
//   this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
//   const me = this
//   updateCurrentOnBoardRequest(me.onBoardRequest)
//     .then(async () => {
//       if (this.file.data) {
//         await this.apiRef.loadRequestFile(this.file, this.item.id)
//       }
//       this.$api.mountNextComponent(me.onBoardRequest)
//     })
//     .catch(error => {
//       const { response } = error
//       if (response) {
//         console.log('response data', response.data)
//         this.errors = response.data
//       }
//       if (response.status !== 400) {
//         this.showMessage(error)
//       }
//     });
// },
//   pickFile() {
//     this.$refs.file.click()
//   },
//   onFilePicked(e) {
//     const files = e.target.files
//     if (files[0] !== undefined) {
//       this.file.name = files[0].name
//       if (this.file.name.lastIndexOf('.') <= 0) {
//         const message = 'File format must be PDF.'
//         this.showMessage({ message })
//         return
//       }
//       const fr = new FileReader()
//       fr.readAsDataURL(files[0])
//       fr.addEventListener('load', () => {
//         this.file.data = files[0];
//       })
//     } else {
//       this.file.name = ''
//       this.file.data = ''
//     }
//   },
// },

export default APIService
