<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmSafetyTraining',
  data: function () {
    return {
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    next() {
      const me = this

      me.onBoardRequest.data.safety_training = 'false'
      me.onBoardRequest.data.tracks.cns_external_user.safety_training_completed.value = 'pending'
      this.$api
        .updateOnBoardRequest(me.onBoardRequest)
        .then(() => this.$api.mountNextComponent(me.onBoardRequest))
        .catch((err) => me.showMessage(err))
    },
  },
  mounted: function () {
    const me = this

    const key = sessionStorage.getItem('onboard_continuation_key')
    if (key) {
      this.$api.getOnBoardRequest(key).then((res) => (me.onBoardRequest = res.data[0]))
    }
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Safety Training</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Egestas maecenas pharetra convallis posuere morbi leo. Ipsum nunc aliquet bibendum enim. Lectus
        arcu bibendum at varius vel pharetra. Ut enim blandit volutpat maecenas volutpat blandit.
      </p>
      <v-btn class="btn" @click.prevent="next()" color="secondary">Next</v-btn>
    </v-flex>
  </v-layout>
</template>
